(function ($) {
  var $main_nav = $("#main-nav");
  var $toggle = $(".toggle");

  var defaultData = {
    maxWidth: false,
    customToggle: $toggle,
    levelTitles: true,
    insertClose: !0,
    closeLevels: false,
  };

  // add new items to original nav
  $main_nav
    .find("li.add")
    .children("a")
    .on("click", function () {
      var $this = $(this);
      var $li = $this.parent();
      var items = eval("(" + $this.attr("data-add") + ")");

      $li.before('<li class="new"><a>' + items[0] + "</a></li>");

      items.shift();

      if (!items.length) {
        $li.remove();
      } else {
        $this.attr("data-add", JSON.stringify(items));
      }

      Nav.update(true);
    });

  // call our plugin
  var Nav = $main_nav.hcOffcanvasNav(defaultData);

  // demo settings update

  const update = (settings) => {
    if (Nav.isOpen()) {
      Nav.on("close.once", function () {
        Nav.update(settings);
        Nav.open();
      });

      Nav.close();
    } else {
      Nav.update(settings);
    }
  };

  $(".actions")
    .find("a")
    .on("click", function (e) {
      e.preventDefault();

      var $this = $(this).addClass("active");
      var $siblings = $this
        .parent()
        .siblings()
        .children("a")
        .removeClass("active");
      var settings = eval("(" + $this.data("demo") + ")");

      update(settings);
    });

  $(".actions")
    .find("input")
    .on("change", function () {
      var $this = $(this);
      var settings = eval("(" + $this.data("demo") + ")");

      if ($this.is(":checked")) {
        update(settings);
      } else {
        var removeData = {};
        $.each(settings, function (index, value) {
          removeData[index] = false;
        });

        update(removeData);
      }
    });
})(jQuery);

//
$(document).ready(function () {
  function switchView(view) {
    if (view === "list") {
      $("#mb_products .mb_item")
        .removeClass("mb_grid_group_item")
        .addClass("mb_list_group_item");
      $("#list").addClass("active");
      $("#grid").removeClass("active");
    } else if (view === "grid") {
      $("#mb_products .mb_item")
        .removeClass("mb_list_group_item")
        .addClass("mb_grid_group_item");
      $("#grid").addClass("active");
      $("#list").removeClass("active");
    }
  }

  $("#list").click(function (event) {
    event.preventDefault();
    switchView("list");
  });

  $("#grid").click(function (event) {
    event.preventDefault();
    switchView("grid");
  });
});

$(document).ready(function () {
  // Show the popup
  $(".mb_noBg").click(function () {
    $(".mb_popup").fadeIn();
  });
  // Hide the popup
  $(".close-btn").click(function () {
    $(".mb_popup").fadeOut();
  });

  // Hide the popup if clicking outside of the popup content
  $(window).click(function (event) {
    if ($(event.target).is(".mb_popup")) {
      $(".mb_popup").fadeOut();
    }
  });
});

// search popup
$(document).ready(function () {
  // Show the popup
  $(".mb_searchclick").click(function () {
    $(".mb_gb_pop").fadeIn();
  });
  // Hide the popup
  $(".close-btn").click(function () {
    $(".mb_gb_pop").fadeOut();
  });

  // Hide the popup if clicking outside of the popup content
  $(window).click(function (event) {
    if ($(event.target).is(".mb_gb_pop")) {
      $(".mb_gb_pop").fadeOut();
    }
  });
});
$(document).ready(function () {
  $("#edit-icon").on("click", function () {
    $("#file-input").click();
  });

  $("#file-input").on("change", function (event) {
    var file = event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $("#profile-img").attr("src", e.target.result);
      };
      reader.readAsDataURL(file);
    }
  });
});

//price range js
$(document).ready(function () {
  const $rangeValue = $(".slider-container .price-slider");
  const $rangeInputValues = $(".range-input input");
  const $priceInputValues = $(".price-input select");

  // Set the price gap
  const priceGap = 500;

  // Populate dropdown options
  function populateDropdowns() {
    const minRange = 0;
    const maxRange = 10000;
    const step = 500;
    let options = "";
    for (let i = minRange; i <= maxRange; i += step) {
      options += `<option value="${i}">${i}</option>`;
    }
    $priceInputValues.each(function () {
      $(this).append(options);
    });
  }

  populateDropdowns();

  function updateRange() {
    const minp = parseInt($priceInputValues.eq(0).val());
    const maxp = parseInt($priceInputValues.eq(1).val());

    if (isNaN(minp) || isNaN(maxp)) return;

    // Ensure min and max are within allowed ranges
    if (minp < 0) {
      $priceInputValues.eq(0).val(0);
    }

    if (maxp > 10000) {
      $priceInputValues.eq(1).val(10000);
    }

    const minVal = parseInt($priceInputValues.eq(0).val());
    const maxVal = parseInt($priceInputValues.eq(1).val());

    // Adjust min and max values if they are too close
    if (minVal > maxVal - priceGap) {
      $priceInputValues.eq(0).val(maxVal - priceGap);
    }

    // Update sliders
    $rangeInputValues.eq(0).val($priceInputValues.eq(0).val());
    $rangeInputValues.eq(1).val($priceInputValues.eq(1).val());

    // Update slider visual
    updateSliderVisual();
  }

  function updateSliderVisual() {
    const minVal = parseInt($rangeInputValues.eq(0).val());
    const maxVal = parseInt($rangeInputValues.eq(1).val());

    const minPercent = (minVal / $rangeInputValues.eq(0).attr("max")) * 100;
    const maxPercent = (maxVal / $rangeInputValues.eq(1).attr("max")) * 100;

    $(".price-slider").css({
      left: `${minPercent}%`,
      right: `${100 - maxPercent}%`,
    });
  }

  function updatePriceInputs() {
    const minVal = parseInt($rangeInputValues.eq(0).val());
    const maxVal = parseInt($rangeInputValues.eq(1).val());
    const diff = maxVal - minVal;

    if (diff < priceGap) {
      if ($(this).hasClass("min-range")) {
        $rangeInputValues.eq(0).val(maxVal - priceGap);
      } else {
        $rangeInputValues.eq(1).val(minVal + priceGap);
      }
    } else {
      $priceInputValues.eq(0).val(minVal);
      $priceInputValues.eq(1).val(maxVal);
      updateSliderVisual();
    }
  }

  // Event listeners for price input elements
  $priceInputValues.on("change", updateRange);

  // Event listeners for range input elements
  $rangeInputValues.on("input", updatePriceInputs);

  // Initialize the dropdowns with the default values
  updateRange();
});
// check add class
$(document).ready(function () {
  // Check initial state of checkbox
  $(".custom-checkbox").each(function () {
    if (this.checked) {
      $(this).closest(".mb-prop-item").addClass("active");
    }
  });

  // Handle change event to toggle class
  $(".custom-checkbox").on("change", function () {
    $(this).closest(".mb-prop-item").toggleClass("active", this.checked);
  });
});
//cibil add
$(document).ready(function () {
  // Show the modal
  $(".addbtn").click(function () {
    $(".mb-cibil-popup").fadeIn();
  });

  // Close the modal
  $(".close-btn").click(function () {
    $(".mb-cibil-popup").fadeOut();
  });

  // Prevent modal from closing when clicking inside it
  $(".mb-cibil-popup").click(function (event) {
    event.stopPropagation();
  });
});
//mb-home-slider
$(document).ready(function () {
  // Initialize the first Swiper instance for the .my-dashboard-slider
  var swiperDashboard = new Swiper(".my-dashboard-slider", {
      pagination: {
          el: ".swiper-pagination",
          clickable: true, // Enable clickable pagination dots
      },
  });

  // Initialize the second Swiper instance for the .search-slider
  var swiperSearch = new Swiper(".search-slider", {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
    },
  });

  // Initialize the second Swiper instance for the .about_slider_wrapper
  var swiperaboutslider = new Swiper(".about_slider_wrapper", {
    slidesPerView: "auto",
    spaceBetween: 10,
    grabCursor: true,
      pagination: {
        el: ".swiper-pagination"
      },
  });
  // Initialize the second Swiper instance for the .amenities_list
  var amenities_list = new Swiper("#amenities_list", {
    slidesPerView: "auto",
    spaceBetween: 10,
    grabCursor: true,
      pagination: {
        el: ".swiper-pagination"
      },
  });
   // Initialize the second Swiper instance for the .bank_slider
   var bank_slider = new Swiper(".bank_slider", {
    slidesPerView: "auto",
    spaceBetween: 10,
    grabCursor: true,
      pagination: {
        el: ".swiper-pagination"
      },
  });
    // Initialize the second Swiper instance for the .ratingreview_details
    var swiperaboutslider = new Swiper(".ratingreview_details", {
      slidesPerView: "auto",
      spaceBetween: 10,
      grabCursor: true,
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  // Initialize the second Swiper instance for the .map_slider_wrapper
  var swiperaboutslider = new Swiper(".map_slider_wrapper", {
    slidesPerView: "auto",
    spaceBetween: 10,
    grabCursor: true,
    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
  // Initialize the second Swiper instance for the .Popluarslider
  var swiperaboutslider = new Swiper(".Popluarslider", {
    slidesPerView: "auto",
    spaceBetween: 10,
    grabCursor: true,
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
    // Initialize the second Swiper instance for the .featureText_slider_main
    var featureMain = new Swiper(".featureText_slider_main", {
      slidesPerView: "auto",
      spaceBetween: 10,
      grabCursor: true,
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
    // Initialize the second Swiper instance for the .property_service_slider
    var swiperaboutslider = new Swiper(".property_service_slider", {
      slidesPerView: "auto",
      spaceBetween: 10,
      grabCursor: true,
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
    // Initialize the second Swiper instance for the .bottomproplistslider
    var bottomproplistslider = new Swiper(".bottomproplistslider", {
      slidesPerView: "auto",
      spaceBetween: 40,
      grabCursor: true,
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
    //photos and videos
    var mediamain = new Swiper(".thumbswiper", {
      loop: true,
      spaceBetween: 10,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var thumbslide = new Swiper(".media_mainSlider", {
      loop: true,
      spaceBetween: 10,

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: mediamain,
      },
    });
    // Locality Gudie
    var localitythumb = new Swiper(".localityThumb", {
      loop: true,
      spaceBetween: 10,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var localitymain = new Swiper(".locationPhotos", {
      loop: true,
      spaceBetween: 10,

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: localitythumb,
      },
    });
}); //end slidedr


$(window).scroll(function() {
  var scroll = $(window).scrollTop(); // Get scroll position
  if (scroll > 50) {  // Change 50 to the desired scroll threshold
    $('.mb-navbar').addClass('mb_navbg');  // Add 'mb_navbg' class to navbar
    $('.mb-menu-container').addClass('mb_navfixedTop');  // Add 'mb_navfixedTop' class to menu container
  } else {
    $('.mb-navbar').removeClass('mb_navbg');  // Remove 'mb_navbg' class
    $('.mb-menu-container').removeClass('mb_navfixedTop');  // Remove 'mb_navfixedTop' class
  }
});

$(document).ready(function() {
  // On clicking a link, scroll to the corresponding section
  $('.menu-list a').on('click', function(e) {
    e.preventDefault(); // Prevent the default anchor behavior

    // Get the target section's id
    var target = $(this).attr('href');

    // Only proceed if target is a valid section ID (not '#')
    if (target !== '#') {
      // Scroll to the target section
      $('html, body').animate({
        scrollTop: $(target).offset().top
      }, 1000);

      // Update the active link
      $('.menu-list a').removeClass('active');
      $(this).addClass('active');
    }
  });

  // On scroll, update the active link based on the section in the viewport
  $(window).on('scroll', function() {
    var scrollPos = $(document).scrollTop();

    // Check each section to see if it's in view
    $('.menu-list a').each(function() {
      var section = $(this).attr('href');

      // Skip links that do not point to valid sections (i.e., '#' or missing target)
      if (section && section !== '#' && $(section).length > 0) {
        var targetSection = $(section);

        // Check if the section is in the viewport
        if (targetSection.offset().top <= scrollPos + 100 && targetSection.offset().top + targetSection.outerHeight() > scrollPos) {
          // Add the active class to the corresponding menu item
          $('.menu-list a').removeClass('active');
          $(this).addClass('active');
        }
      }
    });
  });
});